<template>
  <v-layout style="overflow: hidden" class="projects" column fill-height :class="$vuetify.breakpoint.mdAndUp ? 'ml-5' : 'mx-2'">
    <v-flex style="position: absolute; top: 0; left: 0; width: 100%">
      <v-layout row wrap justify-space-between align-center>
        <a class="btn-back" href="#" @click.prevent="$emit('window', 1)" :class="$vuetify.breakpoint.mdAndUp ? 'ml-5' : 'ml-2'" :style="{
          'left': $vuetify.breakpoint.mdAndUp ? 0 : '',
          'z-index': 2
        }">
          <!-- <v-icon>arrow_right_alt</v-icon> -->
          <img src="assets/icons/Symbol 4 – 1.svg" alt="">
        </a>
        <v-spacer></v-spacer>
        <v-btn flat icon dark @click.stop="drawer = !drawer" style="z-index: 10">
          <v-icon>list</v-icon>
        </v-btn>
      </v-layout>
    </v-flex>

    <v-flex>
      <v-flex xs12 md9>
        <v-window v-model="activeProject" :touchless="activeProject === 0 || $vuetify.breakpoint.mdAndUp" :vertical="$vuetify.breakpoint.mdAndUp" lazy :style="$vuetify.breakpoint.mdAndUp ? 'height: 100vh' : ''">
          <!-- Map -->
          <v-window-item :value="0" :key="'pick-project'" class="fill-height" lazy>
            <v-layout row wrap align-center fill-height>
              <v-flex class="project">
                <v-layout column>

                  <v-flex>
                    <v-layout row wrap justify-space-between :style="$vuetify.breakpoint.mdAndUp ? 'min-height: 15vh' : ''">
                      <v-flex md9 align-self-end>
                        <v-layout class="project__title display-1 mt-5 mb-0 white--text">
                          Velg prosjekt:
                        </v-layout>
                        <v-layout class="project_image-text white--text mb-1 mr-3 caption" justify-end>
                          <v-flex style="max-width: 200px" class="text-xs-right">
                            <!-- {{project.images[activeImage].caption}} -->
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-flex>

                  <v-flex>
                    <v-layout row :style="'height: 75vh'">
                      <Map :projects="projects" :projectHighlighted="projectHighlighted" @projectHovered="setHoveredState" @activateProject="activateProject"></Map>
                    </v-layout>
                  </v-flex>
                  
                </v-layout>
              </v-flex>
            </v-layout>
          </v-window-item>

          <!-- Projects -->
          <v-window-item :value="i+1" v-for="(project, i) in projects" :key="'project-'+i" class="fill-height" lazy>
            <v-layout row wrap align-center fill-height>
              <v-flex class="project">
                <v-layout column>

                  <v-flex>
                    <v-layout row wrap justify-space-between :style="$vuetify.breakpoint.mdAndUp ? 'min-height: 15vh' : ''">
                      <v-flex md9 align-self-end>
                        <v-layout class="project__title display-1 mt-5 mb-0 white--text">
                          <v-flex style="max-width: 500px">
                            {{activeProject !== null ? project.title : 'Velg prosjekt'}}
                          </v-flex>
                        </v-layout>
                        
                        <v-layout v-if="activeProject > 0 && projects[activeProject-1].images.length" class="project_image-text white--text mb-1 mr-3 caption" justify-end>
                          <v-flex style="max-width: 200px" class="text-xs-right">
                            {{projects[activeProject-1].images[activeImage].caption}}
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-flex>

                  <v-flex>
                    <v-card flat dark class="fill-height" style="background-color: #222">
                      <v-layout :class="$vuetify.breakpoint.smAndDown ? 'column' : 'row wrap'" :style="$vuetify.breakpoint.mdAndUp ? 'height: 75vh': ''" justify-end style="font-size: 0.9em; letter-spacing: 0.04em;">
                        
                        <v-flex v-if="activeProject > 0 && projects[activeProject-1].images.length" class="project__image" fill-height :class="moreInfo ? 'md3' : 'md9'">
                          <v-img :src="projects[activeProject-1].images[activeImage].url" cover height="100%" class="grey lighten-2">
                            <v-layout slot="placeholder" fill-height align-center justify-center ma-0>
                              <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                            </v-layout>
                          </v-img>
                        </v-flex>

                        <v-flex md3 fill-height>
                          <v-layout class="project__info-wrapper" column fill-height>
                            <v-flex :ref="'scroll_'+(i+1)" class="project__description" order-xs2 order-md1 style="padding: 10%; padding-top: 15%; height: 70%; overflow-y: hidden">
                              <vue-scrollbar classes="my-scrollbar" ref="Scrollbar">
                                <div class="scroll-me">
                                  <div v-for="info in project.info" class="mb-3">
                                    <div class="font-weight-bold text-uppercase d-inline-block mb-1">
                                      <v-icon color="#ffd12b">{{ getIcon(info.title, info.icon) }}</v-icon> {{info.title}}
                                    </div>
                                    <div class="mb-4">
                                      {{info.text}}
                                    </div>
                                  </div>
                                </div>
                              </vue-scrollbar>
                            </v-flex>

                            <v-flex v-if="project.position.lat !== ''" shrink class="project__video" order-xs3 order-md1>
                              <v-layout row wrap fill-height class="project_video-btn" style="position: relative;">
                                <a class="btn-sidebar" href="#" @click.prevent="() => {
                                  activeProject = 0;
                                  projectHighlighted = i;
                                }"></a>
                                
                                <v-flex shrink style="width: 80px; height: 80px; border-right: 1px solid black">
                                  <v-layout row wrap justify-center align-center fill-height>
                                    <v-icon color="#ffd12b">place</v-icon>
                                  </v-layout>
                                </v-flex>

                                <v-flex>
                                  <v-layout row wrap align-center fill-height ml-4>
                                    Lokasjon
                                  </v-layout>
                                </v-flex>
                              </v-layout>
                            </v-flex>

                            <v-flex v-if="activeProject > 0 && projects[activeProject-1].images.length" shrink class="project__images" order-xs1 order-md2>
                              <v-layout row wrap pa-1 justify-space-around>
                                <v-flex v-for="(image, i) in project.imageThumbs" :key="'image-'+i" :style="activeImage === i ? '' : 'opacity: 0.5'">
                                  <a href="#" @click.prevent="activeImage = i">
                                    <v-img class="ma-1" :src="image.url" aspect-ratio="1" max-width="100px"></v-img>
                                  </a>
                                </v-flex>
                              </v-layout>
                            </v-flex>

                            <!-- Make ifs on these -->
                            <!-- <v-flex v-if="project.videoUrl.length" shrink class="project__video" order-xs3>
                              <v-layout row wrap fill-height>
                                <v-flex shrink class="project_video-btn" style="width: 80px; height: 80px; border-right: 1px solid black">
                                  <v-layout row wrap justify-center align-center fill-height>
                                    <a href="#" @click.prevent="playVideo()"></a>
                                    <v-icon>play_arrow</v-icon>
                                  </v-layout>
                                </v-flex>

                                <v-flex>
                                  <v-layout row wrap justify-center align-center fill-height>
                                    Showcase
                                  </v-layout>
                                </v-flex>
                              </v-layout>
                            </v-flex> -->

                            <!-- <v-flex class="project__map">
                              Location
                            </v-flex> -->
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </v-flex>
                </v-layout>


              </v-flex>
            </v-layout>
          </v-window-item>
        </v-window>
      </v-flex>

      <v-flex md3>
        <v-navigation-drawer
          v-model="drawer"
          dark
          right
          floating
          :class="$vuetify.breakpoint.smAndDown ? 'fixed' : 'no-bg'"
          fixed
          mobile-break-point="1000"
          style="box-shadow: none;"
          hide-overlay
          >
          <v-flex class="timeline" align-self-center>
            <v-layout row wrap justify-end fill-height :class="$vuetify.breakpoint.mdAndUp ? 'align-center' : ''">
              <v-flex xs12 md10>
                <v-layout column>
                  <v-spacer :style="$vuetify.breakpoint.mdAndUp ? 'min-height: 15vh' : ''"></v-spacer>
                  <v-flex xs12 :style="$vuetify.breakpoint.mdAndUp ? 'height: 75vh' : ''">
                    <v-timeline align-top dense dark class="pt-0">
                      
                      <v-timeline-item small color="white" :color="'transparent'" :key="'map-picker'">
                        <v-layout class="year" pt-3>
                          <a href="#" class="white--text" style="text-decoration: none" @click.prevent="() => {activeProject = 0; activeYear = null; openedYear = null}">
                            Velg prosjekt
                          </a>
                        </v-layout>
                      </v-timeline-item>

                      <v-timeline-item small v-for="(year, i) in projectYearsUnique" color="white" :color="openedYear === i ? 'brand-bg' : 'white'" :key="'project-'+year">
                        <v-layout class="year" pt-3>
                          <a href="#" class="white--text" style="text-decoration: none" @click.prevent="openedYear = i">
                            <strong>{{year}}</strong>
                          </a>
                        </v-layout>

                        <v-expand-transition>
                          <v-layout class="projects" :class="openedYear === i ? 'active' : ''" column v-show="openedYear === i">
                            <a 
                              class="mt-2 white--text" 
                              :class="[project.loopOrder === activeProject ? 'active' : '', project.loopOrder === hoveredProject ? 'map-hovered' : '']" 
                              href="#" 
                              v-for="(project, ii) in projects" 
                              @click.prevent="() => {activeProject = project.loopOrder; activeYear = i; hoveredProject = null; activeImage = 0}" 
                              :key="'title-'+ii"
                              v-if="project.year === year"
                              style="text-decoration: none"
                            >
                              <span></span>
                              <div style="width: 100%">
                                {{project.title}}
                              </div>
                            </a>
                          </v-layout>
                        </v-expand-transition>

                      </v-timeline-item>
                    </v-timeline>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-navigation-drawer>
      </v-flex>
    </v-flex>
    
  </v-layout>
</template>

<script>
import VueScrollbar from 'vue2-scrollbar';

export default {
  props: ['projects'],
  components: { VueScrollbar },
  computed: {
    activeProjectObj () {
      const project = this.years[this.activeYear].projects.find((project) => {
        console.log(project.loopOrder)
        return project.loopOrder === this.activeProject
      })

      return project
    },
    projectsExtracted () {
      return this.years.reduce((result, year,i) => {
        result = result.concat(year.projects)
        return result
      }, [])
    },
    // drawer () {
    //   return this.$vuetify.breakpoint.mdAndUp ? true : false
    // }
  },
  data () {
    return {
      moreInfo: false,
      drawer: null,
      openedYear: null,
      activeYear: null,
      activeProject: 0,
      projectHighlighted: null,
      hoveredProject: null,
      activeImage: 0,
      projectYears: [],
      projectYearsUnique: []
    }
  },
  methods: {
    getIcon (title, icon) {
      if (title == 'Lokasjon') return 'location_on'
      else if (title == 'Kunde') return 'domain'
      else if (title == 'Prosjekt') return 'settings_applications'
      else if (title == 'Leveranse') return 'done'
      else return icon
    },
    playVideo () {
      // alert('Start video')
    },
    activateProject (projectIndex) {
      console.log(projectIndex)
      this.activeProject = projectIndex
    },
    setHoveredState (data) {
      this.openedYear = this.projectYearsUnique.indexOf(data.year)
      this.hoveredProject = data.index
    },
    extractYears () {
      this.projects.map((project, i) => {
        const year = new Date(project.date).getFullYear()
        this.projectYears.push(year)
        this.projects[i]['year'] = year
        this.projects[i]['index'] = i
      })

      this.projectYearsUnique = this.projectYears.filter((year, i, years) => {
        return !i || year !== years[i-1]
      })
    }
  },
  created () {
    this.extractYears()
  },
  watch: {
    activeProject: function () {
      this.$nextTick(() => {
        // this.$vuebar.initScrollbar(this.$refs['scroll-'+this.activeProject])
        // this.$vuebar.refreshScrollbar(this.$refs['scroll-'+this.activeProject])

        setTimeout(() => {
          // Last seen her!!!!!!!
          this.$vuebar.refreshScrollbar(this.$refs['scroll_'+this.activeProject])
          console.log(this.$refs)
          console.log(this.$refs['scroll_'+this.activeProject])
          console.log(this.$vuebar.getState(this.$refs['scroll_'+this.activeProject]))
        }, 500)
      })
    }
  },
  mounted () {
    // console.log(this.$vuebar)
  }
}
</script>

<style lang="stylus">
$dark-bg = #222
$dark-border-color = lighten($dark-bg, 10%)

.dark-bg
  background-color $dark-bg
  border-color $dark-border-color

.map-hovered
  color #ffd12b !important

.v-timeline.pt-0.v-timeline--align-top.v-timeline--dense.theme--dark:after {
  content: '';
  position: absolute;
  left: 18px;
  width: 1px;
  height: 100px;
  bottom: 100%;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,1+100 */
  background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.btn-back
  transform rotate(-180deg)

.year, .projects
  transition all .2s ease

.projects
  >a
    position relative
    transition color .2s ease
    span
      width 15px
      position absolute 
      left 0
      bottom 0
      top 0
      margin auto 0
      transition width .2s ease
      &:before
        content: '';
        background: white;
        width: 100%;
        height: 1px;
        position: absolute;
        left: -45px;
        top: 0;
        bottom: 0;
        margin-top: auto;
        margin-bottom: auto;
        animation: grow-from-left .5s 1 ease
    &.active
      span
        width 38px
        &:before
          // animation: grow-from-left-active .3s 1 ease

.project__info-wrapper
  >div
    border-bottom 1px solid $dark-border-color

.project_video-btn
  position relative
  a
    position absolute
    left 0
    right 0
    bottom 0
    top 0
    margin auto
    width 100%
    height 100%

.timeline
.timeline > div
  backface-visibility hidden

// Overrides
.theme--dark.v-navigation-drawer
  &.no-bg
    background-color transparent
.v-timeline-item__dot--small
  backface-visibility hidden
  background transparent !important
  box-shadow none
  .v-timeline-item__inner-dot
    height: 13px;
    margin: 5px;
    width: 13px;
    transition all .2s ease
    &.white
      // background-color transparent !important
      border 1px solid white
      transform: scale(0.7)

.theme--dark.v-timeline:before
  background-color white
  width 1px

@keyframes grow-from-left {
  0% {
    width 0
  }
  100% {
    width 100%
  }
}

// @keyframes grow-from-left-active {
//   0% {
//     width 15px
//   }
//   100% {
//     width 38px
//   }
// }

// VueScrollBar
.vue-scrollbar-transition, .vue-scrollbar__scrollbar-vertical, .vue-scrollbar__scrollbar-horizontal {
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}
.vue-scrollbar-transition--scrollbar {
  transition: opacity 0.5s linear;
  -moz-transition: opacity 0.5s linear;
  -webkit-transition: opacity 0.5s linear;
  -o-transition: opacity 0.5s linear;
}

.vue-scrollbar__wrapper {
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  background: white;
}
.vue-scrollbar__wrapper:hover .vue-scrollbar__scrollbar-vertical, .vue-scrollbar__wrapper:hover .vue-scrollbar__scrollbar-horizontal {
  opacity: 1;
}
.vue-scrollbar__scrollbar-vertical, .vue-scrollbar__scrollbar-horizontal {
  opacity: 0.5;
  position: absolute;
  background: transparent;
}
.vue-scrollbar__scrollbar-vertical:hover, .vue-scrollbar__scrollbar-horizontal:hover {
  background: rgba(0, 0, 0, 0.3);
}
.vue-scrollbar__scrollbar-vertical .scrollbar, .vue-scrollbar__scrollbar-horizontal .scrollbar {
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  cursor: default;
}
.vue-scrollbar__scrollbar-vertical {
  width: 10px;
  height: 100%;
  top: 0;
  right: 0;
}
.vue-scrollbar__scrollbar-vertical .scrollbar {
  width: 10px;
}
.vue-scrollbar__scrollbar-horizontal {
  height: 10px;
  width: 100%;
  bottom: 0;
  right: 0;
}
.vue-scrollbar__scrollbar-horizontal .scrollbar {
  height: 10px;
}

// Custom styling
.vue-scrollbar__scrollbar-vertical .scrollbar, .vue-scrollbar__scrollbar-horizontal .scrollbar{
  background-color: #606060;
}
.my-scrollbar{
  width: 100%;
  // min-width: 300px;
  max-height: 100%;
  padding-right: 10px;
}
/*The Content*/
.scroll-me{
  // min-width: 750px;
}
.vue-scrollbar__wrapper{
  background-color: transparent;
} 
</style>
