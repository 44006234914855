import '@babel/polyfill'
import Vue from 'vue'
import './plugins/vuetify'
import './plugins/map'
import './plugins/vuebar'
import './plugins/vuescrollbar'
import './stylus/main.styl'
import Components from './components'
import './app.css'

Vue.config.productionTip = false

new Vue({
  el: '#app',
  delimiters: ['${', '}']
})
