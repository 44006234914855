<template>
  <v-window v-model="window" touchless>
    <v-window-item :value="1" :class="$vuetify.breakpoint.mdAndUp ? 'fill-height' : ''">
      
      <v-layout column :class="$vuetify.breakpoint.mdAndUp ? 'justify-center fill-height' : ''" :style="$vuetify.breakpoint.mdAndUp ? '' : 'height: 100vh'">

        <v-flex shrink :style="$vuetify.breakpoint.mdAndUp ? 'height: 40vh' : ''">
          <v-layout column justify-space-between fill-height>
            <v-flex class="pb-5">
              <v-layout style="position: relative" :class="$vuetify.breakpoint.mdAndUp ? 'row wrap align-center' : 'column'">
                
                <v-flex xs7>
                  <v-layout row wrap>
                    <Logo :english="english" :homepage="true"></Logo>
                    <Sticks v-if="$vuetify.breakpoint.mdAndUp" class="mr-5" style="transform: translateY(5rem);"></Sticks>
                  </v-layout>
                </v-flex>
                <v-flex :class="$vuetify.breakpoint.mdAndUp ? '' : ''" xs4 shrink class="white--text" style="">
                  <v-layout class="font-weight-regular display-2 text-uppercase text-xs-center text-md-left" :class="$vuetify.breakpoint.mdAndUp ? 'row wrap justify-space-between' : 'column mt-5'">
                    <span :style="$vuetify.breakpoint.mdAndUp ? 'font-size: 0.80em' : 'font-size: 0.65em'">Prosjekter</span>
                    <v-spacer></v-spacer>
                    <a href="#" class="btn-transparent d-flex align-center" style="text-decoration: none" @click.prevent="window = 2">
                      <img src="assets/icons/Symbol 4 – 1.svg" alt="">
                    </a>
                  </v-layout>
                </v-flex>

              </v-layout>
            </v-flex>

            <!-- <v&#45;flex style="height: 3px;"> -->
              <v-layout style="position: relative; flex: 0;" :class="$vuetify.breakpoint.mdAndUp ? 'row wrap align-center' : 'column pb-5'">
                <v-flex class="" offset-md1 md10>
                  <hr>
                </v-flex>
              </v-layout>
            <!-- </v&#45;flex> -->

            <!-- <hr style="position: relative;" :style="$vuetify.breakpoint.mdAndUp ? 'margin&#45;left: calc(100vw/12); margin&#45;right: calc(100vw/12);' : 'padding&#45;left: 30px; padding&#45;right: 30px;'"> -->
            
            <v-flex :class="$vuetify.breakpoint.mdAndUp ? '' : 'mx-3'" :style="$vuetify.breakpoint.mdAndUp ? '' : ''">
              <v-layout column justify-space-between fill-height class="white--text">
                <v-flex class="my-5" offset-md1 md5>
                  <v-layout row wrap fill-height align-center>
                    <v-flex xs10 sm6 :class="$vuetify.breakpoint.mdAndUp ? 'pr-5' : ''" style="max-width: 850px">
                      <span v-html="splashtext"></span>
                    </v-flex>
                  </v-layout>
                </v-flex>
                
              </v-layout>
            </v-flex>

          </v-layout>
        </v-flex>

        <v-flex :shrink="$vuetify.breakpoint.mdAndUp">
          <v-layout row wrap :class="$vuetify.breakpoint.mdAndUp ? '' : 'fill-height'" :style="$vuetify.breakpoint.mdAndUp ? 'min-height: 250px;' : ''">
            <v-flex xs12>
              <v-layout column wrap fill-height>
                <v-flex offset-md1>
                  <v-layout row :class="$vuetify.breakpoint.mdAndUp ? 'align-center' : 'px-3'">
                    <h2 class="mb-3 white--text">Våre forretningssegment</h2>
                  </v-layout>
                  <v-layout row wrap class="services" :class="$vuetify.breakpoint.mdAndUp ? 'align-center' : 'px-3'">
                    <!-- Tjenester: -->
                    <a v-for="(article, i) in services" :href="article.url" class="brand-text services-item" :key="'service-'+i">

                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <span v-on="on" v-html="article.icon"></span>
                        </template>
                        <span>{{article.title}}</span>
                      </v-tooltip>
                    </a>
                  </v-layout>
                </v-flex>
                <v-spacer></v-spacer>
                <v-flex d-flex align-end :class="$vuetify.breakpoint.mdAndUp ? '' : 'mt-3'">
                  <v-layout :class="$vuetify.breakpoint.mdAndUp ? 'row' : 'column justify-between fill-height'">
                    <v-flex class="footer__wrapper" offset-md1 md6 order-xs2 order-md1>
                      <Footer class="footer row wrap" :articles="articles"></Footer>
                    </v-flex>
                    <v-flex class="news__wrapper" md5 order-xs1 order-md2 :class="$vuetify.breakpoint.mdAndUp ? '' : 'mx-3'">
                      <v-layout row class="white--text" fill-height align-center>
                        <!-- <pre>{{news}}</pre> -->
                        <v-flex :style="$vuetify.breakpoint.mdAndUp ? 'max-width: 700px;' : ''" pr-5>
                          <v-layout row>
                            <span class="pr-2">Nyheter:</span>
                            <span class="news__box" style="max-width: 70%">
                              <marquee-text :duration="20">
                                <div style="width: 100%;">
                                  <span v-for="article in news" class="mr-5" style="padding: 0 50px;">
                                    <a :href="article.url">
                                      {{article.title}}
                                    </a>
                                  </span>
                                </div>
                              </marquee-text>
                            </span>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>

          </v-layout>
        </v-flex>

      </v-layout>
    </v-window-item>
    
    <v-window-item lazy :value="2" :class="$vuetify.breakpoint.mdAndUp ? 'fill-height' : ''">
      <references @window="setWindow" :projects="projects"></references>
    </v-window-item>
  </v-window>
</template>

<script>
import MarqueeText from 'vue-marquee-text-component'

export default {
  props: ['articles', 'projects', 'splashtext', 'english'],
  components: { MarqueeText },
  computed: {
    services () {
      return this.articles.filter(article => article.type === 2)
    },
    news () {
      return this.articles.filter(article => article.type === 4)
    },
    newsTitles () {
      return this.news.map(newItem => newItem.title)
    }
  },
  data () {
    return {
      mounted: false,
      window: 1,
      currentIndex: 0,
      currentCaption: ''
    }
  },
  methods: {
    setWindow (window) {
      this.window = window
    }
  },
  mounted () {
    this.mounted = true
  }
}
</script>


<style lang="stylus">
.news__box
  border-left 1px solid white
  border-right 1px solid white
  a
    color white
    text-decoration none

.vue-flux
  height 250px
  // width 100%
  .mask
    opacity .5

.vue-flux__wrapper
  position relative
  .slider-caption
    position absolute
    text-align center
    width: 100%
    bottom 40px
    left 0
    right 0
    margin 0 auto
    z-index 1
    color white
    a
      color white
      text-decoration none

.v-window__container
  height 100%
.splash
  @media (min-width 960px)
    height 100vh
.btn-transparent
  display block
  // padding 10px 15px
  text-align center
  // min-width 250px
  @media (min-width 960px)
    // border 1px solid white
.splashtext
  // color white

.services
  .services-item
    margin-right 16px
    @media (min-width 960px)
      margin-right 48px
    
  svg,
  svg path
    fill #ffd12b
    // stroke #ffd12b
  svg
    width auto
    height 55px
    @media (min-width 960px)
      /* height 95px */
      height 120px

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
