<template>
  <v-layout>
    <v-flex class="white--text" tag="footer">
      <v-layout :class="$vuetify.breakpoint.mdAndUp ? 'row wrap align-center fill-height align-end' : 'column mx-3'">
        <v-flex order-xs2 order-md1 pr-4 shrink>
          ©{{new Date().getFullYear()}} Vertikalservice
        </v-flex>
        <v-flex order-xs1 order-md2>
          <v-layout row wrap shrink class="main-menu" tag="nav">
            <a v-for="article in articles" v-if="article.type === 1" :href="article.url" class="mr-3" :class="$vuetify.breakpoint.mdAndUp ? '' : 'mb-3'" style="font-size: 0.8em">
              {{article.title}}
            </a>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  props: ['articles']
}
</script>
