<template>
  <googlemaps-map
    :center.sync="center"
    :zoom.sync="zoom"
    :options="{
      zoomControl: false,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      disableDefaultUi: false,
      styles: mapStyle.styles
    }"
    style="width: 100%; height: 100%"
    @idle="onIdle"
    >
    <googlemaps-marker
      ref="myMarker"
      :key="'marker-'+index"
      v-for="(project, index) in projects"
      :position="Object.keys(project.position).reduce((result, key, i) => {
        result[key] = Number(project.position[key])
        return result
      }, {})"
      :icon="{
        path: 'M24.6.1v12.3l-7.3-5.1V0C7.5 1.7 0 10.3 0 20.7 0 31 7.5 39.6 17.3 41.3V28.8l7.3 5.1v7.5c9.9-1.8 17.3-10.4 17.3-20.7 0-10.3-7.5-18.9-17.3-20.6zM9.9 9.3l1.3-1.9c.8-1.1 2.4-1.4 3.5-.6l15.1 10.4c1.1.8 1.4 2.3.6 3.5l-1.3 1.9c-.8 1.1-2.3 1.4-3.5.6L10.5 12.8c-1.1-.8-1.4-2.3-.6-3.5zM32.3 32L31 33.9c-.8 1.1-2.3 1.4-3.5.6L12.4 24.1c-1.1-.8-1.4-2.3-.6-3.5l1.3-1.9c.8-1.1 2.3-1.4 3.5-.6l15.1 10.4c1 .8 1.3 2.3.6 3.5z',
        fillColor: '#ffd12b',
        fillOpacity: 1,
        strokeWeight: 0,
        scale: 0.5
      }"
      @mouseover="$emit('projectHovered', {index: project.loopOrder, year: project.year})"
      @click="$emit('activateProject', project.loopOrder)"
    />
  </googlemaps-map>
</template>

<script>
import { MapElement } from 'vue-googlemaps'

// Those Vue props will update automatically
// (Two-way binding with .sync modifier)
const boundProps = [
  'animation',
  'clickable',
  'cursor',
  'draggable',
  // ...
]

// Events from Google Maps emitted as Vue events
const redirectedEvents = [
  'click',
  'rightclick',
  'dblclick',
  'drag',
  // ...
]

export default {
  mixins: [
    // You need to use this mixin
    MapElement,
  ],

  computed: {
    // google: () => true
    // google: gmapApi
  },

  props: ['projects'],
  methods: {},

  // When Google Maps is ready
  googleMapsReady () {
    const options = Object.assign({}, this.$props)
    options.map = this.$_map

    // Create Google Maps objects
    this.$_marker = new window.google.maps.Marker(options)
    // Bind the Vue props
    this.bindProps(this.$_marker, boundProps)
    // Emit the events from Google Maps
    this.redirectEvents(this.$_marker, redirectedEvents)
  },

  beforeDestroy () {
    // Teardown
    if (this.$_marker) {
      this.$_marker.setMap(null)
    }
  },

  data () {
    return {
      google: true,
      center: {lat: 58.964432, lng: 5.726250},
      zoom: 5,
      mapStyle: {
        styles: [
          {
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#212121"
              }
            ]
          },
          {
            "elementType": "labels",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          },
          {
            "elementType": "labels.icon",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          },
          {
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#757575"
              }
            ]
          },
          {
            "elementType": "labels.text.stroke",
            "stylers": [
              {
                "color": "#212121"
              }
            ]
          },
          {
            "featureType": "administrative",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#757575"
              },
              {
                "visibility": "off"
              }
            ]
          },
          {
            "featureType": "administrative.country",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#9e9e9e"
              }
            ]
          },
          {
            "featureType": "administrative.land_parcel",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          },
          {
            "featureType": "administrative.locality",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#bdbdbd"
              }
            ]
          },
          {
            "featureType": "administrative.neighborhood",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          },
          {
            "featureType": "poi",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          },
          {
            "featureType": "poi",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#757575"
              }
            ]
          },
          {
            "featureType": "poi.park",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#181818"
              }
            ]
          },
          {
            "featureType": "poi.park",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#616161"
              }
            ]
          },
          {
            "featureType": "poi.park",
            "elementType": "labels.text.stroke",
            "stylers": [
              {
                "color": "#1b1b1b"
              }
            ]
          },
          {
            "featureType": "road",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          },
          {
            "featureType": "road",
            "elementType": "geometry.fill",
            "stylers": [
              {
                "color": "#2c2c2c"
              }
            ]
          },
          {
            "featureType": "road",
            "elementType": "labels.icon",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          },
          {
            "featureType": "road",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#8a8a8a"
              }
            ]
          },
          {
            "featureType": "road.arterial",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#373737"
              }
            ]
          },
          {
            "featureType": "road.highway",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#3c3c3c"
              }
            ]
          },
          {
            "featureType": "road.highway.controlled_access",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#4e4e4e"
              }
            ]
          },
          {
            "featureType": "road.local",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#616161"
              }
            ]
          },
          {
            "featureType": "transit",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          },
          {
            "featureType": "transit",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#757575"
              }
            ]
          },
          {
            "featureType": "water",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#000000"
              }
            ]
          },
          {
            "featureType": "water",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#3d3d3d"
              }
            ]
          }
        ]
      }
    }
  },
  mounted () {
    console.log('qwrw')
  }
}
</script>
