<template>
  <v-flex class="sticks" :class="[bglight ? 'bg-light' : 'bg-dark', {'inline': inline}]" shrink px-3>
    <div class="float-ease-in"></div>
    <div class="float-ease-in-out"></div>
  </v-flex>
</template>

<script>
export default {
  props: ['bglight', 'inline'],
  data () {
    return {}
  }
}
</script>

<style lang="stylus">
sticksBaseHeightMobileInline = 25px
sticksBaseHeightDesktopInline = 45px

sticksBaseHeightMobile = 20vh
sticksBaseHeightDesktop = 30vh
.sticks
  @media (max-width: 960px)
    position: absolute;
    left: auto;
    right: 16px;
    margin: auto;
    bottom: 0;

  width 20px
  position relative
  z-index 10
  div
    position absolute
    width 1px
    margin auto
    background-color white
    &:first-child,
    &:last-child
      top 0
      left 0
      top 0
      bottom 0
    &:first-child
      height sticksBaseHeightMobile
      top 20vh
    &:last-child
      height sticksBaseHeightMobile * 1.1
      left 10px
    @media (min-width: 960px)
      &:first-child
        height sticksBaseHeightDesktop
        top 20vh
      &:last-child
        height sticksBaseHeightDesktop * 1.1
        left 20px
  &.inline
    position: relative;
    left: 0;
    right: auto;
    margin: 0;
    bottom: auto;

    div
      &:first-child
        height sticksBaseHeightMobileInline
        top 10px
      &:last-child
        height sticksBaseHeightMobileInline * 1.4
        left 0
      @media (min-width: 960px)
        &:first-child
          height sticksBaseHeightDesktopInline
          top 10px
        &:last-child
          height sticksBaseHeightDesktopInline * 1.4
          left 7px
  &.bg-light
    >div
      background-color black

// Animations
.float-ease-in {
  animation: float 3s infinite ease-in;
}
.float-ease-in-out {
  animation: float 3.5s infinite ease-in-out;
}

@keyframes float {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(1rem);
  }

  100% {
    transform: translateY(0);
  }
}
</style>