<template>
  <v-layout column>
    <v-flex grow :style="$vuetify.breakpoint.mdAndUp ? '' : 'max-width: 90%'">
      <v-layout row wrap @mouseover="logoHovered = true" @mouseleave="logoHovered = false" class="logo" style="position: relative" :class="$vuetify.breakpoint.mdAndUp ? 'justify-end' : 'justify-space-between'" :style="$vuetify.breakpoint.mdAndUp ? 'padding-left: calc(100vw/12)' : 'padding-left: 30px'">
        <div class="language__links" :class="{'homepage': homepage}">
          <a href="/" :class="{'active': !english.active}">no</a> /
          <a :href="english.url" :class="{'active': english.active}">en</a>
        </div>

        <v-slide-y-transition>
          <v-flex v-if="logoHovered || $vuetify.breakpoint.smAndDown" class="social-icons" style="width: 100%">
            <v-layout row wrap justify-end>
              <a href="https://www.facebook.com/VertikalServiceAs/" target="_blank" class="no-underline hover:text-grey">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72 72"><path d="M72 12c0-6.3-5.7-12-12-12H12C5.7 0 0 5.7 0 12v48c0 6.3 5.7 12 12 12h24V44.8h-8.8v-12H36v-4.7c0-8.1 6.1-15.3 13.5-15.3h9.7v12h-9.7c-1.1 0-2.3 1.3-2.3 3.2v4.8h12v12h-12V72H60c6.3 0 12-5.7 12-12V12zm0 0" /></svg>
              </a>
              <a href="https://www.instagram.com/vertikalservice/" target="_blank" class="no-underline hover:text-grey">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="-49 141 512 512"><path d="M303 141H111c-88.352 0-160 71.648-160 160v192c0 88.352 71.648 160 160 160h192c88.352 0 160-71.648 160-160V301c0-88.352-71.648-160-160-160zm112 352c0 61.76-50.24 112-112 112H111C49.24 605-1 554.76-1 493V301c0-61.76 50.24-112 112-112h192c61.76 0 112 50.24 112 112v192z" /><path d="M207 269c-70.688 0-128 57.312-128 128s57.312 128 128 128 128-57.312 128-128-57.312-128-128-128zm0 208c-44.096 0-80-35.904-80-80 0-44.128 35.904-80 80-80s80 35.872 80 80c0 44.096-35.904 80-80 80z" /><circle cx="344.6" cy="259.4" r="17.056" /></svg>
              </a>
            </v-layout>
          </v-flex>
        </v-slide-y-transition>

        <v-flex>
          <v-img src="/assets/logo/logo-01.svg" class="mr-4 my-3" aspect-ratio="9" contain :max-width="$vuetify.breakpoint.mdAndUp ? '300px' : '260px'">
            <v-fade-transition>
              <v-flex v-if="adminPanel" class="admin-panel" style="background: black" fill-height>
                <v-layout row wrap align-center fill-height justify-space-between>
                  <a href="/admin">Logg inn</a>
                  <a href="/i">Intranet 2.0</a>
                  <a href="/webmail">Webmail</a>
                  <a href="http://bss.vertikalservice.no">BSS</a>
                </v-layout>
              </v-flex>
              <a class="logo__link" @ v-else href="/"></a>
            </v-fade-transition>
          </v-img>
        </v-flex>
        <v-flex class="admin-panel-trigger" shrink>
          <a href="#" class="admin-panel-trigger__link" @click.prevent="toggle('adminPanel')"></a>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  props: ['english', 'homepage'],
  data () {
    return {
      adminPanel: false,
      logoHovered: false
    }
  },
  methods: {
    toggle (elm) {
      this[elm] = !this[elm]
    }
  }
}
</script>


<style lang="stylus">
.logo
  background-color black
  max-width 500px
.admin-panel
  a
    // text-decoration none
    color white
.admin-panel-trigger
  position relative
  .admin-panel-trigger__link
    width 5px
    background-color #ffd12b
    height 100%
    position absolute
    right 0
    top 0
    height 100%
.logo__link
  position absolute
  left 0
  top 0
  width 100%
  height 100%
  z-index 0
.social-icons
  position absolute
  left 0
  bottom 100%
  svg
    width 25px
    margin-right 10px
    fill black
    .home &
      fill white
</style>
