var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('GmapMap',{staticStyle:{"width":"100%","height":"100%"},attrs:{"center":_vm.mapPosition,"zoom":_vm.zoom,"map-type-id":"terrain","options":{
    zoomControl: false,
    mapTypeControl: false,
    scaleControl: false,
    streetViewControl: false,
    rotateControl: false,
    fullscreenControl: false,
    disableDefaultUi: false,
    styles: _vm.mapStyle.styles
  }}},_vm._l((_vm.projects),function(project,index){return _c('GmapMarker',{key:index,ref:"myMarker",refInFor:true,attrs:{"position":Object.keys(project.position).reduce(function (result, key, i) {
      result[key] = Number(project.position[key])
      return result
    }, {}),"clickable":true,"draggable":true,"icon":{
      path: 'M24.6.1v12.3l-7.3-5.1V0C7.5 1.7 0 10.3 0 20.7 0 31 7.5 39.6 17.3 41.3V28.8l7.3 5.1v7.5c9.9-1.8 17.3-10.4 17.3-20.7 0-10.3-7.5-18.9-17.3-20.6zM9.9 9.3l1.3-1.9c.8-1.1 2.4-1.4 3.5-.6l15.1 10.4c1.1.8 1.4 2.3.6 3.5l-1.3 1.9c-.8 1.1-2.3 1.4-3.5.6L10.5 12.8c-1.1-.8-1.4-2.3-.6-3.5zM32.3 32L31 33.9c-.8 1.1-2.3 1.4-3.5.6L12.4 24.1c-1.1-.8-1.4-2.3-.6-3.5l1.3-1.9c.8-1.1 2.3-1.4 3.5-.6l15.1 10.4c1 .8 1.3 2.3.6 3.5z',
      fillColor: '#ffd12b',
      fillOpacity: 1,
      strokeWeight: 0,
      scale: _vm.projectHighlighted === index ? 0.6 : 0.3,
      size: _vm.google && new _vm.google.maps.Size(0, 0),
      origin: _vm.google && new _vm.google.maps.Point(0, 0),
      anchor: _vm.google && new _vm.google.maps.Point(11, 11)
    }},on:{"mouseover":function($event){return _vm.$emit('projectHovered', {index: project.loopOrder, year: project.year})},"click":function($event){return _vm.$emit('activateProject', project.loopOrder)}}})}),1)}
var staticRenderFns = []

export { render, staticRenderFns }