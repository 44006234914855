<template>
  <v-layout mt-4 style="z-index: 10; position: relative;">
    <v-flex :class="fulltextLength < fewCharsLimit && $vuetify.breakpoint.xlAndUp ? 'md3' : ''">
      <div class="article-content" :class="{'has-image': imageexists}" :style="fulltextLength < fewCharsLimit && $vuetify.breakpoint.xlAndUp || $vuetify.breakpoint.smAndDown || !imageexists ? 'column-count: 1;' : 'column-count: 3; padding-top: 50vh;'">
        <h1 
          class="display-2 grey--text text--darken-2 mt-3" 
          :style="fulltextLength < fewCharsLimit && $vuetify.breakpoint.xlAndUp || $vuetify.breakpoint.smAndDown || !imageexists ? '' : 'margin-top: -50vh !important;'" 
          :class="$vuetify.breakpoint.mdAndUp ? 'mr-5' : ''"
        >
          {{title}}
        </h1>
        <span class="mb-5 lead text--grey text--lighten-3" v-html="introtext" style="
          padding-top: 30px;
          font-size: 1.15em;
          letter-spacing: 0.03rem;
          line-height: 1.4em;
        "></span>
        <span class="fulltext" v-html="fulltext" style="
          letter-spacing: -0.03em;
          line-height: 1.5em;
        "></span>
      </div>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  props: ['title', 'introtext', 'fulltext', 'imageexists'],
  data () {
    return {
      fulltextLength: 0,
      fewCharsLimit: 1500
    }
  },
  mounted () {
    this.fulltextLength = this.fulltext ? this.fulltext.length : 0
  }
}
</script>

<style lang="stylus">
.article-content
  &.has-image
    @media (max-width 600px)
      column-count: 1 !important
      padding-top: 31vh
    @media (min-width 600px) and (max-width 960px)
      column-count: 2 !important
      padding-top: 31vh
</style>
